<template>
  <div class="check-ranking flex-column">
    <div class="nameTit flex-space-between">
      <div class="tp-left flex-center-v">
        <span style="margin-right: 10px;">企业/项目检查排名</span>
        <el-date-picker
            size="small"
            v-model="timer"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            style="width: 400px;margin-right: 10px;">
        </el-date-picker>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
        <el-button type="primary" size="small" style="width:80px;" @click="exportTaiBan">导出</el-button>
      </div>
      <div class="tp-right flex-center-v">
      </div>
    </div>
    <div class="one flex">
      <div class="oneItem">
        <div>检查总数</div><span>{{ details.checkCount || 0 }}</span>
      </div>
      <div style="margin: 0 5px;border-right: 1px dashed #dcdcdc;height: 100%;"></div>
      <div class="oneItem">
        <div>平均检查数量/项目</div><span>{{ details.avgCheckCount || 0 }}</span>
      </div>
      <div style="margin: 0 5px;border-right: 1px dashed #dcdcdc;height: 100%;"></div>
      <div class="oneItem">
        <div>重大问题数</div><span>{{ details.importantCount || 100 }}</span>
      </div>
      <div style="margin: 0 5px;border-right: 1px dashed #dcdcdc;height: 100%;"></div>
      <div class="oneItem">
        <div>未整改重大问题</div><span>{{ details.rectifyImportantCount || 0 }}</span>
      </div>
      <div style="margin: 0 5px;border-right: 1px dashed #dcdcdc;height: 100%;"></div>
      <div class="oneItem">
        <div>超期未整改</div><span>{{ details.notRectifyLimitCount || 0 }}</span>
      </div>
      <div class="oneItem">
        <div>超期未销项</div><span>{{ details.notReviewLimitCount || 0 }}</span>
      </div>
      <div style="margin: 0 5px;border-right: 1px dashed #dcdcdc;height: 100%;"></div>
      <div class="oneItem">
        <div>及时整改率</div><span>{{ details.rectifyLimitRate || 0 }}%</span>
      </div>
    </div>
    <div class="cont flex-1-auto flex">
      <el-table ref="multipleTable" :data="details.dtoList" height="100%" style="width: 100%" :header-cell-style="{background:'#f2f2f2',color:'#333333'}">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" label="企业/项目名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="checkCount" label="检查总数" align="center" sortable></el-table-column>
        <el-table-column prop="avgCheckCount" label="平均检查数量/项目" align="center" sortable></el-table-column>
        <el-table-column prop="importantCount" label="重大问题数" align="center" sortable></el-table-column>
        <el-table-column prop="rectifyImportantCount" label="未整改重大问题" align="center" sortable></el-table-column>
        <el-table-column prop="notRectifyLimitCount" label="超期未整改" align="center" sortable></el-table-column>
        <el-table-column prop="notReviewLimitCount" label="超期未销项" align="center" sortable></el-table-column>
        <el-table-column prop="rectifyLimitRate" label="及时整改率" align="center" sortable>
          <template slot-scope="{row}">
            <span>{{ row.rectifyLimitRate }}%</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import apis from "@/views/menus/system-setup/components/apis3";
import {downloadFile} from '@/utils/utils'
import BaseURL from '@/apis/baseurl'
export default {
  name: "check-ranking",

  components: {},

  props: {},

  data() {
    return {
      details: {
        dtoList: [],
        checkCount: '',
        avgCheckCount: '',
        importantCount: '',
        rectifyImportantCount: '',
        notRectifyLimitCount: '',
        notReviewLimitCount: '',
        rectifyLimitRate: ''
      },
      timer: '',
      searchData: {
        checkStartDate: '',
        checkEndDate: '',
        sort: '',
        sortField: ''
      },
    }
  },

  computed: {},

  watch: {},

  mounted() {
    this.getTableList().async
  },

  methods: {
    async getTableList() {
      const result = await apis.companyData(this.searchData)
      this.details = result
    },
    exportTaiBan(){
      const url = BaseURL + `/admin/public/exportCompanyData?checkStartDate=${this.searchData.checkStartDate}&checkEndDate=${this.searchData.checkEndDate}&sort=${this.searchData.sort}&sortField=${this.searchData.sortField}`
      downloadFile(url, {}, '企业/项目检查排名.xls')
    },
    // 查询
    search() {
      if (this.timer) {
        this.searchData.checkStartDate = this.timer[0]
        this.searchData.checkEndDate = this.timer[1]
      } else {
        this.searchData.checkStartDate = ''
        this.searchData.checkEndDate = ''
      }
      this.getTableList().async
    },
    sortChange({ prop, order }) {
      if (order) {
        if (order == 'ascending') this.searchData.sort = 'asc'
        else this.searchData.sort = 'desc'
        this.searchData.sortField = prop
        this.getTableList().async
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.check-ranking {
  height 100%
  padding 10px
  .one {
    padding 20px 0
    border-radius 5px
    background-color #f1f1f1
    margin 10px 0
    .oneItem {
      width 16.6%
      display flex
      flex-direction column
      > div {
        margin-bottom 5px
      }
      >span {
        font-size 25px
      }
    }
  }
}
</style>
